import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import SpotlightButton from '../../ui/spotlight-button';

export default function ContactBox() {
  const [form, setForm] = useState({
    user_name: '',
    user_email: '',
    message: '',
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const serviceId = 'service_eng67no';
    const templateId = 'template_pquj1gd';
    const publicKey = 'JQedkqw9a9ZZrhks5';

    try {
      await emailjs.send(serviceId, templateId, form, publicKey);
      setSuccess(true);
      setForm({ user_name: '', user_email: '', message: '' });
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="py-10 flex flex-col justify-start items-start bg-[#141c29] px-4 md:px-10 rounded-[24px] w-full mx-auto">
      <h1 className="text-left text-3xl sm:text-4xl md:text-5xl font-semibold text-white font-titillium">
        READY TO TELL YOUR STORY?
      </h1>

      <form className="mt-10 flex flex-col gap-6 font-poppins w-full" onSubmit={handleSubmit}>
        <label className="flex flex-col">
          <input
            type="text"
            name="user_name"
            value={form.user_name}
            onChange={handleChange}
            placeholder="name"
            className="bg-transparent 
              placeholder:text-[#9999a1] placeholder:uppercase
              text-[#d4d4d8] rounded-lg outline-none
              border-none font-medium text-xl sm:text-3xl md:text-4xl"
          />
        </label>
        <label className="flex flex-col">
          <input
            type="email"
            name="user_email"
            value={form.user_email}
            onChange={handleChange}
            placeholder="email"
            className="bg-transparent 
              placeholder:text-[#9999a1] placeholder:uppercase
              text-[#d4d4d8] rounded-lg outline-none
              border-none font-medium text-xl sm:text-3xl md:text-4xl"
          />
        </label>
        <label className="flex flex-col">
          <textarea
            rows={5}
            name="message"
            value={form.message}
            onChange={handleChange}
            placeholder="how can i help you?"
            className="bg-transparent 
              placeholder:text-[#9999a1] placeholder:uppercase
              text-[#d4d4d8] rounded-lg outline-none
              border-none font-medium text-xl sm:text-3xl md:text-4xl"
          />
        </label>
        <div className="w-full">
          <SpotlightButton text={loading ? 'Sending...' : 'Send'} />
        </div>
      </form>
      {success && (
        <p className="text-green-500 mt-4 text-lg text-center w-full font-montserrat">Message sent successfully!</p>
      )}
      {error && (
        <p className="text-red-500 mt-4 text-lg text-center w-full font-montserrat">
          Failed to send message. Please try again.
        </p>
      )}
    </div>
  );
}
