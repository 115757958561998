import { frontend, backend, uiux, mobile } from '../../assets/images';
import { FaNodeJs, FaReact } from 'react-icons/fa';
import { RiNextjsFill } from 'react-icons/ri';
import {
  SiAmazonaws,
  SiDart,
  SiDocker,
  SiFigma,
  SiFirebase,
  SiFlutter,
  SiMongodb,
  SiMysql,
  SiPostman,
  SiPrisma,
  SiPython,
  SiRedux,
  SiSupabase,
} from 'react-icons/si';
import { TbBrandThreejs } from 'react-icons/tb';
import React from 'react';

export const navLinks = [
  {
    id: 'about',
    title: 'About',
    path: '#about',
  },
  {
    id: 'works',
    title: 'Works',
    path: '#works',
  },
  {
    id: 'contact',
    title: 'Contact',
    path: '#contact',
  },
];

export const SERVICES = [
  {
    title: 'Frontend Developer',
    icon: frontend,
  },
  {
    title: 'Backend Developer',
    icon: backend,
  },
  {
    title: 'Mobile App Developer',
    icon: mobile,
  },
  {
    title: 'UI/UX Design',
    icon: uiux,
  },
];

const techClass = 'h-[60px] w-[60px] sm:h-[70px] sm:w-[70px] md:h-[85px] md:w-[85px] lg:h-[100px] lg:w-[100px]';

export const TECHNOLOGIES = [
  {
    name: 'React',
    icon: <FaReact style={{ color: '#44a6c6' }} className={techClass} />,
  },
  {
    name: 'Next',
    icon: <RiNextjsFill style={{ color: '#44a6c6' }} className={techClass} />,
  },
  {
    name: 'Redux',
    icon: <SiRedux style={{ color: '#44a6c6' }} className={techClass} />,
  },
  {
    name: 'NodeJs',
    icon: <FaNodeJs style={{ color: '#44a6c6' }} className={techClass} />,
  },
  {
    name: 'Flutter',
    icon: <SiFlutter style={{ color: '#44a6c6' }} className={techClass} />,
  },
  {
    name: 'Firebase',
    icon: <SiFirebase style={{ color: '#44a6c6' }} className={techClass} />,
  },
  {
    name: 'Supabase',
    icon: <SiSupabase style={{ color: '#44a6c6' }} className={techClass} />,
  },
  {
    name: 'MySQL',
    icon: <SiMysql style={{ color: '#44a6c6' }} className={techClass} />,
  },
  {
    name: 'MongoDB',
    icon: <SiMongodb style={{ color: '#44a6c6' }} className={techClass} />,
  },
  {
    name: 'Figma',
    icon: <SiFigma style={{ color: '#44a6c6' }} className={techClass} />,
  },
  {
    name: 'Threejs',
    icon: <TbBrandThreejs style={{ color: '#44a6c6' }} className={techClass} />,
  },
  {
    name: 'Postman',
    icon: <SiPostman style={{ color: '#44a6c6' }} className={techClass} />,
  },
  {
    name: 'Docker',
    icon: <SiDocker style={{ color: '#44a6c6' }} className={techClass} />,
  },
  {
    name: 'AWS',
    icon: <SiAmazonaws style={{ color: '#44a6c6' }} className={techClass} />,
  },
  {
    name: 'Javascript',
    icon: <SiFigma style={{ color: '#44a6c6' }} className={techClass} />,
  },
  {
    name: 'Typescript',
    icon: <TbBrandThreejs style={{ color: '#44a6c6' }} className={techClass} />,
  },
  {
    name: 'Dart',
    icon: <SiDart style={{ color: '#44a6c6' }} className={techClass} />,
  },
  {
    name: 'Python',
    icon: <SiPython style={{ color: '#44a6c6' }} className={techClass} />,
  },
  {
    name: 'Prisma',
    icon: <SiPrisma style={{ color: '#44a6c6' }} className={techClass} />,
  },
];

export const SLUGS = [
  'typescript',
  'javascript',
  'dart',
  'java',
  'react',
  'flutter',
  'android',
  'html5',
  'css3',
  'nodedotjs',
  'express',
  'nextdotjs',
  'prisma',
  'amazonaws',
  'postgresql',
  'firebase',
  'redux',
  'vercel',
  'testinglibrary',
  'jest',
  'cypress',
  'docker',
  'git',
  'jira',
  'github',
  'gitlab',
  'visualstudiocode',
  'androidstudio',
  'sonarqube',
  'figma',
];
