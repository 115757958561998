import React from 'react';
import { footerBg } from '../../../assets/images';

const Footer = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${footerBg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
      className="w-full relative overflow-hidden h-[150vh] lg:h-[200vh] bg-bottom"
    >
      {/* Blue color layer added with opacity */}
      <div className="absolute inset-0 bg-blue_bg opacity-50"></div>
      <div className="absolute inset-0 flex flex-col items-center justify-between text-white z-10">
        <div className="flex flex-col justify-center items-center lg:w-[20%] md:w-[60%] w-[70%] py-40 mx-auto cursor-default">
          <p className="text-white text-[16px] font-montserrat font-normal py-2 text-center lg:text-left">
            I thrive on the challenge of creating unique and amazing digital experiences. I see technology as a way to
            bring our ideas to life and solve problems.
          </p>
          <p className="text-white text-[16px] font-montserrat font-normal py-2 text-center lg:text-left">
            There’s always more to achieve, as each line of code is a step towards building a better, more connected
            world.
          </p>
          <p className="text-white text-[16px] font-montserrat font-normal py-2 text-center lg:text-left">
            I'm just getting started, and the journey ahead is full of endless possibilities.
          </p>
        </div>
        <div className="flex flex-col items-center justify-center w-full pb-4">
          <Title title="Chamara" />
          <Title title="Wimalaweera" />
          <div className="text[16px] font-medium font-montserrat text-gray-400 uppercase py-10 cursor-default">
            © 2024, All Rights Reserved
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

const Title = ({ title }: { title: string }) => {
  return (
    <div className="text-white text-center lg:text-left text-5xl sm:text-6xl md:text-8xl lg:text-9xl uppercase font-titillium font-bold cursor-default">
      {title}
    </div>
  );
};
