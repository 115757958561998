import React from 'react';
import { Stars } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { aboutColor, aboutPhoto } from '../../../assets/images';
import IconCloud from '../../ui/iconcloud';
import { SLUGS } from '../../../lib/constants/Navigation';
import SpotlightContact from '../../ui/spotlight-contactme';

const phoneNumber = '94764200132'; // Replace with your phone number

const About = () => {
  return (
    <section className="relative grid w-full bg-blue_bg cursor-default">
      <div className="relative w-full  z-10 pt-4 pb-24 lg:py-32 max-w-[1920px] mx-auto">
        <div className="w-full flex flex-col lg:flex-row justify-between items-start pt-32 px-5 sm:px-12 gap-6">
          <div className="text[16px] font-medium font-mova text-gray-400 uppercase pb-10">\ about</div>
          <div className="flex flex-col text-right">
            <Paragraph text="web developer," />
            <Paragraph text="mobile app developer," />
            <Paragraph text="ui/ux designer and" />
            <Paragraph text="undergraduate" />
          </div>
          <div className="w-full lg:w-[35%] py-10 lg:py-0">
            <div className="text-white text-[16px] font-montserrat font-normal">
              I'm not just an average developer—I'm a passionate web and mobile app developer with a keen eye for UI/UX
              design. My job is to create seamless digital experiences that leave a lasting impression.
            </div>
            <div className="text-white text-sm font-montserrat font-normal py-4">
              Off-duty, When I'm not working, I enjoy exploring new places, listening to music, and reading books. But
              my greatest joy comes from collaborating with you. Let's chat and turn your ideas into reality.
            </div>
            <a href={`https://wa.me/${phoneNumber}`} target="_blank" rel="noopener noreferrer" className="w-full">
              <SpotlightContact text="Let's chat" />
            </a>
          </div>
        </div>
        <div className="py-10 lg:py-24 lg:px-40 flex flex-col md:flex-row items-start gap-10 px-5 sm:px-12">
          <img
            src={aboutPhoto}
            alt=""
            className="w-full md:w-1/2 lg:w-1/2 rounded-xl"
            loading="lazy"
            srcSet={`${aboutPhoto} 1x, ${aboutPhoto} 2x`}
          />
          <img
            src={aboutColor}
            alt=""
            className="w-full md:w-1/2 lg:w-1/2 rounded-xl h-full"
            loading="lazy"
            srcSet={`${aboutColor} 1x, ${aboutColor} 2x`}
          />
        </div>
        <div className="text-[16px] font-medium font-mova text-gray-400 uppercase mt-10 py-10 text-center">
          tech stack
        </div>
        {/* <TechNavigate /> */}
        <IconCloud iconSlugs={SLUGS} />
      </div>
      <div className="absolute inset-0 z-0">
        <Canvas>
          <Stars radius={50} count={4000} factor={4} fade speed={2} />
        </Canvas>
      </div>
    </section>
  );
};

export default About;

const Paragraph = ({ text }: { text: string }) => {
  return (
    <p className="text-white text-left text-4xl sm:text-5xl md:text-6xl font-titillium  uppercase font-[600]">{text}</p>
  );
};
