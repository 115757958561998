import React from 'react';
import { Stars } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import ContactBox from './ContactBox';

export default function Contact() {
  const phoneNumber = '94764200132'; // Replace with your phone number
  const emailAddress = 'contact@chamarawimalaweera.com';

  return (
    <section className="relative grid w-full bg-blue_bg cursor-default overflow-hidden">
      <div className="relative w-full z-10 py-[200px] max-w-[1920px] mx-auto">
        <div className="w-full flex flex-col lg:flex-row justify-start items-start px-5 sm:px-12 gap-6">
          <div className="text-[16px] font-medium font-mova text-gray-400 uppercase pb-10 lg:w-[20%] w-full">
            \ contact
          </div>

          <div className="w-full lg:w-[60%] py-10 lg:py-0">
            <Title title="Get in touch" />

            {/* Email */}
            <div className="flex flex-col items-start pt-[100px]">
              <a
                href={`mailto:${emailAddress}`}
                className="text-white text-left text-3xl sm:text-4xl md:text-5xl font-titillium font-normal hidden lg:flex hover:text-gray-400"
              >
                {emailAddress}
              </a>
              <div className="text-white text-left text-2xl sm:text-3xl md:text-4xl font-titillium font-normal flex flex-col lg:hidden hover:text-gray-400">
                <a href={`mailto:${emailAddress}`}>
                  <p>contact@</p>
                  <p>chamarawimalaweera.com</p>
                </a>
              </div>
              <div className="w-full h-0.5 bg-gray-400 mt-3" />
            </div>

            {/* Linkedin and Whatsapp */}
            <div className="flex flex-col lg:flex-row lg:justify-between gap-10 items-start w-full py-12">
              <div className="w-full">
                <div
                  className="text-white text-left text-3xl sm:text-4xl md:text-5xl font-titillium font-normal hover:text-gray-400 cursor-pointer"
                  onClick={() => window.open('https://www.linkedin.com/in/chamarawimalaweera/', '_blank')}
                >
                  Linkedin
                </div>
                <div className="w-full h-0.5 bg-gray-400 mt-3" />
              </div>
              <div className="w-full">
                <a
                  href={`https://wa.me/${phoneNumber}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white text-left text-3xl sm:text-4xl md:text-5xl font-titillium font-normal hover:text-gray-400"
                >
                  Whatsapp
                </a>
                <div className="w-full h-0.5 bg-gray-400 mt-3" />
              </div>
            </div>

            <ContactBox />
          </div>
        </div>
      </div>
      <div className="absolute inset-0 z-0">
        <Canvas>
          <Stars radius={50} count={1000} factor={4} fade speed={2} />
        </Canvas>
      </div>
    </section>
  );
}

const Title = ({ title }: { title: string }) => {
  return (
    <div className="text-white text-left text-4xl sm:text-5xl md:text-6xl font-titillium uppercase font-[600]">
      {title}
    </div>
  );
};
