import { animate, useMotionValue, motion, useMotionTemplate } from 'framer-motion';
import React, { useEffect } from 'react';
import { Stars } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { spaceship } from '../../../assets/images';
import Meteors from '../../ui/meteor';
import FlipText from '../../ui/flip-text';
import SpotlightContact from '../../ui/spotlight-contactme';
import { GlobeDialog } from '../../ui/globe-dialog';
import { Button } from '../../ui/button';

const COLORS_TOP = ['#0A0A0A', '#e3dac9', '#362624', '#efdfbb'];

const Hero = () => {
  const color = useMotionValue(COLORS_TOP[0]);

  useEffect(() => {
    animate(color, COLORS_TOP, {
      ease: 'easeInOut',
      duration: 10,
      repeat: Infinity,
      repeatType: 'mirror',
    });
  }, [color]);

  const backgroundGradient = useMotionTemplate`radial-gradient(125% 125% at 50% 0%, #1c283b 55%, ${color})`;

  return (
    <motion.section
      style={{
        background: backgroundGradient,
        position: 'relative',
      }}
      className="relative grid min-h-screen overflow-hidden lg:px-10 px-4 pb-5 lg:pb-24 text-font_primary cursor-default"
    >
      <div className="relative flex flex-col lg:flex-row items-center lg:items-end justify-end gap-4 lg:gap-24 xl:gap-48 z-10 max-w-[1920px] mx-auto">
        <div>
          <Title title="Chamara" />
          <Title title="Wimalaweera" />
        </div>
        <div>
          <p className="text-center text-base font-normal font-montserrat text-neutral-200 sm:text-left">
            Every project is a unique challenge, and I’m excited to tackle yours. I&apos;m a developer, who loves to
            craft new things that stand out, based in{' '}
            <GlobeDialog>
              <Button
                variant="ghost"
                className="relative inline-block h-auto p-0 text-base font-montserrat font-bold underline hover:bg-transparent active:bg-transparent"
              >
                <div className="absolute -right-1.5 -top-0.5 size-2.5 animate-pulse rounded-full bg-red-500" />
                Sri Lanka
              </Button>
            </GlobeDialog>
            .
          </p>
          <div className="flex justify-center lg:justify-start mb-3">
            <a href="#contact" className="w-full">
              <SpotlightContact text="contact me" />
            </a>
          </div>
        </div>
      </div>

      <AlienShipXL />
      <AlienShipMD />
      <AlienShipXS />

      <div className="absolute inset-0 z-0">
        <Meteors number={50} />
        <Canvas>
          <Stars radius={50} count={1000} factor={4} fade speed={3} />
        </Canvas>
      </div>
    </motion.section>
  );
};

export default Hero;

const Title = ({ title }: { title: string }) => {
  return (
    <div>
      <FlipText
        className="text-white text-center lg:text-left text-5xl sm:text-6xl md:text-8xl uppercase font-titillium font-bold"
        word={title}
      />
    </div>
  );
};

const AlienShipXL = () => {
  return (
    <motion.img
      src={spaceship}
      alt="Alien Ship"
      initial={{ x: '-100%' }}
      animate={{
        x: '550%',
        y: ['0%', '-5%', '0%', '5%', '0%'],

        transition: {
          x: { delay: 1, duration: 18, repeat: Infinity, ease: 'linear', repeatType: 'mirror' },
          y: { duration: 2, repeat: Infinity, repeatType: 'mirror', ease: 'easeInOut' },
        },
      }}
      className="w-[300px] h-auto absolute bottom-[50%] left-0 hidden lg:flex z-5"
    />
  );
};

const AlienShipMD = () => {
  return (
    <motion.img
      src={spaceship}
      alt="Alien Ship"
      initial={{ x: '-100%' }}
      animate={{
        x: '420%',
        y: ['0%', '-5%', '0%', '5%', '0%'],

        transition: {
          x: { delay: 1, duration: 15, repeat: Infinity, ease: 'linear', repeatType: 'mirror' },
          y: { duration: 2, repeat: Infinity, repeatType: 'mirror', ease: 'easeInOut' },
        },
      }}
      className="w-[300px] h-auto absolute bottom-[50%] left-0 hidden md:flex lg:hidden"
    />
  );
};

const AlienShipXS = () => {
  return (
    <motion.img
      src={spaceship}
      alt="Alien Ship"
      initial={{ x: '-100%' }}
      animate={{
        x: '200%',
        y: ['0%', '-5%', '0%', '5%', '0%'],

        transition: {
          x: { delay: 1, duration: 8, repeat: Infinity, ease: 'linear', repeatType: 'mirror' },
          y: { duration: 2, repeat: Infinity, repeatType: 'mirror', ease: 'easeInOut' },
        },
      }}
      className="w-[250px] h-auto absolute bottom-[50%] left-0 flex sm:hidden"
    />
  );
};
