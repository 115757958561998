import { useState } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';
import { navLinks } from '../../../lib/constants/Navigation';
import { motion, AnimatePresence } from 'framer-motion';

const Navbar = () => {
  const [active, setActive] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const phoneNumber = '94764200132';

  return (
    <nav className="w-[100%] fixed z-[999] bg-transparent h-[70px] rounded-bl-[24px] rounded-br-[24px] px-5 sm:px-8 flex flex-row items-center justify-between backdrop-blur-sm">
      <div className="hidden w-full lg:flex justify-between items-center px-4 py-[40px] max-w-[1920px] mx-auto">
        <Link
          to="/"
          className="flex items-center gap-2 text-white
                hover:text-gray-200 text-sm font-medium font-mova 
                uppercase tracking-[3px] cursor-pointer z-50"
          onClick={() => {
            setActive('');
            window.scrollTo(0, 0);
          }}
        >
          C \ W
        </Link>
        <ul className="list-none hidden sm:flex flex-row gap-12">
          {navLinks.map((nav) => (
            <li
              key={nav.id}
              className={`${
                active === nav.title ? 'text-gray-500' : 'text-white'
              } hover:text-white text-sm font-medium font-mova 
                uppercase tracking-[3px] cursor-pointer nav-links`}
              onClick={() => setActive(nav.title)}
            >
              <a href={`#${nav.id}`}>{nav.title}</a>
            </li>
          ))}
        </ul>
      </div>

      {/* Mobile menu */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ y: '-100%' }}
            animate={{ y: 0 }}
            exit={{ y: '-100%' }}
            transition={{ duration: 0.25, ease: 'easeInOut' }}
            className="lg:hidden fixed h-screen z-50 bg-blue_bg w-full top-0 left-0 overflow-hidden"
          >
            <motion.div
              initial={{ opacity: 0, y: '2%' }}
              animate={{ opacity: 1, y: '0' }}
              transition={{ delay: 0.4, duration: 0.15, ease: 'easeIn' }}
            >
              <div className="flex w-full flex-row items-start justify-between px-5 py-6">
                <div
                  className="text-white hover:text-gray-200 text-sm font-medium font-mova uppercase cursor-pointer"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    toggleMenu();
                  }}
                >
                  C \ W
                </div>
                <div
                  className="text-white hover:text-gray-200 text-sm font-medium font-mova uppercase cursor-pointer"
                  onClick={toggleMenu}
                >
                  [Close]
                </div>
              </div>
              <div className="text-left text-lg w-full h-full mt-10">
                <div className="flex flex-col gap-8 py-10 px-5 text-white list-none">
                  {navLinks.map((item) => (
                    <ul className="list-none">
                      <a href={item.path}>
                        <li
                          key={item.id}
                          className="text-xl font-normal text-white font-mova hover:text-gray-200 uppercase cursor-pointer"
                          onClick={toggleMenu}
                        >
                          {item.title}
                        </li>
                      </a>
                    </ul>
                  ))}
                </div>
                <span className="mb-10 mt-[100px] flex flex-col justify-end px-5 gap-8 items-start text-xl font-normal font-mova text-gray-400 uppercase cursor-pointer">
                  <div onClick={() => window.open('https://www.linkedin.com/in/chamarawimalaweera/', '_blank')}>
                    Linkedin
                  </div>
                  <div>
                    <a href={`https://wa.me/${phoneNumber}`} target="_blank" rel="noopener noreferrer">
                      Whatsapp
                    </a>
                  </div>
                </span>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Hamburger menu */}
      <div className="lg:hidden flex w-full justify-between items-center">
        <div>
          <Link
            to="/"
            className="text-white hover:text-gray-200 text-sm font-medium font-mova uppercase cursor-pointer"
            onClick={() => {
              setActive('');
              window.scrollTo(0, 0);
            }}
          >
            C \ W
          </Link>
        </div>
        <button className="text-black focus:outline-none" onClick={toggleMenu}>
          <div className="text-white hover:text-gray-200 text-sm font-medium font-mova uppercase cursor-pointer">
            [Menu]
          </div>
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
