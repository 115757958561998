import './App.css';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Hero, NavBar, About, Footer, Contact } from './components';

export default function App() {
  return (
    <BrowserRouter>
      <NavBar />
      <div id="hero">
        <Hero />
      </div>
      <div id="about">
        <About />
      </div>
      {/* <div id="works">
        <Works />
      </div> */}

      <div id="contact">
        <Contact />
      </div>
      <Footer />
    </BrowserRouter>
  );
}
